@tailwind base;
@tailwind components;
@tailwind utilities;

header {
    background-color: white;
  }
  
  nav a {
    background-color: transparent; /* Ensure menu items have no background color */
    color: #6b21a8; /* Purple color for text */
  }
  
  nav a:hover {
    background-color: transparent; /* Ensure no background color on hover */
    color: #5b1999; /* Slightly darker purple on hover */
  }
  

  df-messenger {
    z-index: 999;
    position: fixed;
    bottom: 16px;
    right: 16px;
  }